<template>
    <div>
        <div class="active">
            <div class="active-text">JOINING DIARY </div>
            <div class="active-text">加盟日记</div>
        </div>
        <div class="all">
            <div class="center" v-for="(item,index) in diaryList" :key="item.id" :class="active == index?'msg':''">
                <div class="center-title">
                    <div>
                        {{item.name}}
                    </div>
                </div>
                <div class="center-content">

                    <div v-html="item.content"></div>
                </div>

                <!-- <div class="center-author">
                    {{item.author}}
                </div> -->

                <div class="center-list">
                    <span v-for="(item,index) in 3" :key="item" :class="active == index?'single':''"
                        @click="reset(item)"></span>
                </div>
            </div>

            <div class="imglist" v-if="imglist[0]">
                <img ref="imglist" alt="" v-for="(item,index) in imglist" :key="item.id" :src="$imgURL+item"
                    :class="'image'+index">
            </div>
        </div>



    </div>
</template>

<script>
import Swiper from 'swiper'

export default {
    data() {
        return {
            active: 0,
            diaryList: [],
            imglist: []
        }
    },
    mounted() {

        this.getdiaryList()
    },
    methods: {

        reset(active) {
            if (active - 1 != this.active) {
                console.log(this.$refs.imglist);
                // 上一个在头位置的窗口直接移动到最后
                this.$refs.imglist[this.active].className = "image0 fromx"
                // 其他窗口前移
                for (let i = 0; i < 3; i++) {
                    if (i != (active - 1) && i != this.active) {
                        // 判断中间窗口是否相较于上次位置没有变化
                        // 没有变化则类名不变动画已完成，需要调整位置使其触发动画，
                        if (this.$refs.imglist[i].className == 'image2 fromx2') {
                            this.$refs.imglist[active - 1].className = "image1 fromx1"
                            this.$refs.imglist[i].className = "image2 fromx2"
                            this.$refs.imglist[i].classList.remove("fromx2")
                            // 必须延时增加动画，否则不生效
                            setTimeout(() => {
                                this.$refs.imglist[i].classList.add("fromx2")
                            }, 0)

                            // image0 fromx1
                        }
                        // 有变化则正常触发
                        else {
                            this.$refs.imglist[active - 1].className = "image1 fromx1"
                            this.$refs.imglist[i].className = "image2 fromx2"
                        }
                        break
                    }
                }



                this.active = active - 1
            }

        },
        // 对图片顺序进行排列 
        resetimg() {
            console.log("d", this.diaryList);
            this.imglist = this.diaryList.map((val) => {
                console.log("kk", val);
                return val.image
            })
            console.log("图片列表", this.imglist);
        },
        transform(e) {
            console.log("事件", e.currentTarget.className = "moveimg");
        },
        // 获取加盟日记列表
        getdiaryList() {
            this.$axios({
                method: "post",
                url: this.$apiURL + "/api/index/rijiList",
            }).then((res) => {

                this.diaryList = res.data.data
                console.log("轮播图列表", this.diaryList);
                this.$nextTick(() => {
                    this.resetimg()
                })
            });
        }

    }
}
</script>

<style lang="scss" scoped>
@import url("../../node_modules/swiper/css/swiper.css");

@keyframes fromx {
    0% {
        top: 0.8rem;
        right: 0rem;
        z-index: 3;
        right: 0;
    }

    25% {
        right: -8rem;
    }

    65% {
        right: -8rem;
    }

    100% {
        top: 0;
        z-index: 1;
        right: -1.6rem;
    }
}

@keyframes fromx1 {
    0% {
        top: 0.4rem;
        right: -0.8rem;
        z-index: 2;
    }

    100% {
        top: 0.8rem;
        z-index: 3;
        right: 0;
    }
}

@keyframes fromx2 {
    0% {
        top: 0;
        right: -1.6rem;
        z-index: 1;
    }

    100% {
        top: 0.4rem;
        z-index: 2;
        right: -0.8rem;
    }
}

.active {
    padding-top: 1.64rem;
    width: 100%;
    height: 5.46rem;
    background-color: #FFFFFF;
    display: flex;
    flex-flow: column;
    justify-content: center;

    &-text {
        text-align: center;
        width: 66%;
        margin: 0 auto;

        &:nth-child(1) {
            font-size: .66rem;
            font-family: Galvji;
            font-weight: bold;
            color: #000000;
        }

        &:nth-child(2) {
            font-size: .42rem;
            font-family: Heiti SC;
            font-weight: 300;
            color: #000000;
            margin: .68rem auto;
        }
    }
}

.moveimg {
    transform: translateX(100%);
    transition-duration: .2s;
    transition-timing-function: ease
}

.msg {
    display: block !important;

    div {
        .single {
            background-color: #FDD23E;
        }
    }
}

.all {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;

    .imglist {
        height: 11.4rem;
        width: 38%;
        position: relative;
        overflow: hidden;

        img {
            display: block;
            width: 90%;
            height: 10.54rem;
            background: #EFEFEF;
            box-shadow: 0px .25rem .37rem .09rem rgba(0, 0, 0, 0.1);
            border-radius: .4rem;
            position: absolute;
            object-fit: unset;
        }

        .image0 {
            top: 0.8rem;
            right: 0;
            z-index: 3;
        }

        .image1 {
            right: -0.8rem;
            top: 0.4rem;
            z-index: 2;
        }

        .image2 {
            right: -1.6rem;
            z-index: 1;
        }

        .fromx {
            animation-name: fromx;
            animation-duration: 2s;
            animation-fill-mode: forwards;
        }

        .fromx1 {
            animation-name: fromx1;
            animation-duration: 2s;
            animation-fill-mode: forwards;
        }

        .fromx2 {
            animation-name: fromx2;
            animation-duration: 2s;
            animation-fill-mode: forwards;
        }

    }
}

.center {
    width: 50%;
    margin-left: 10%;
    position: relative;
    margin-top: .18rem;
    display: none;

    &-title {
        height: .82rem;
        background-color: #FFFFFF;
        display: flex;
        align-items: center;

        div {
            display: inline-block;
            font-size: .3rem;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #FDD23E;
            line-height: .82rem;
        }
    }

    &-content {
        font-size: .2rem;
        font-family: Heiti SC;
        font-weight: 500;
        color: #000000;
        line-height: .36rem;
        margin-top: .9rem;
    }

    &-author {
        margin-top: .5rem;
        font-size: .2rem;
    }

    &-list {
        position: absolute;
        bottom: 0;
        display: flex;

        span {
            display: block;
            width: .27rem;
            height: .27rem;
            background: #E0E0E0;
            border-radius: 50%;
            margin-right: .32rem;
            cursor: pointer;
        }
    }
}
</style>